export const rates = [
  {name: "AFGHANISTAN", five: 19, ten: 39, twenty: 78},
  {name: "AFGHANISTAN-CELLULAR", five: 17, ten: 35, twenty: 70},
  {name: "AFGHANISTAN-CELLULAR SPECIAL", five: 17, ten: 35, twenty: 70},
  {name: "ALBANIA", five: 48, ten: 96, twenty: 193},
  {name: "ALBANIA-CELLULAR", five: 22, ten: 44, twenty: 88},
  {name: "ALBANIA-TIRANA", five: 80, ten: 160, twenty: 320},
  {name: "ALGERIA", five: 26, ten: 52, twenty: 105},
  {name: "ALGERIA-CELLULAR", five: 11, ten: 23, twenty: 46},
  {name: "AMERICAN SAMOA", five: 22, ten: 45, twenty: 91},
  {name: "ANDORRA", five: 119, ten: 238, twenty: 477},
  {name: "ANDORRA-CELLULAR", five: 15, ten: 30, twenty: 61},
  {name: "ANGOLA", five: 30, ten: 60, twenty: 121},
  {name: "ANGOLA-CELLULAR", five: 44, ten: 88, twenty: 176},
  {name: "ANGUILLA", five: 27, ten: 55, twenty: 110},
  {name: "ANGUILLA-CELLULAR", five: 29, ten: 59, twenty: 119},
  {name: "ANTARCTICA", five: 3, ten: 7, twenty: 14},
  {name: "ANTARCTICA-CASEY", five: 3, ten: 7, twenty: 14},
  {name: "ANTIGUA AND BARBUDA", five: 34, ten: 68, twenty: 137},
  {name: "ANTIGUA AND BARBUDA-AUDIOTEXT", five: 34, ten: 68, twenty: 137},
  {name: "ANTIGUA AND BARBUDA-CELLULAR", five: 29, ten: 58, twenty: 116},
  {name: "ARGENTINA", five: 119, ten: 238, twenty: 477},
  {name: "ARGENTINA-BUENOS AIRES", five: 156, ten: 312, twenty: 625},
  {name: "ARGENTINA-CELLULAR", five: 26, ten: 52, twenty: 105},
  {name: "ARGENTINA-CORDOBA", five: 179, ten: 359, twenty: 719},
  {name: "ARGENTINA-LA PLATA", five: 264, ten: 529, twenty: 1058},
  {name: "ARGENTINA-MAR DEL PLATA", five: 186, ten: 373, twenty: 746},
  {name: "ARGENTINA-MENDOZA", five: 125, ten: 250, twenty: 500},
  {name: "ARGENTINA-ROSARIO", five: 264, ten: 529, twenty: 1058},
  {name: "ARMENIA", five: 37, ten: 75, twenty: 150},
  {name: "ARMENIA-CELLULAR", five: 22, ten: 44, twenty: 88},
  {name: "ARMENIA-CELLULAR SPECIAL", five: 22, ten: 44, twenty: 88},
  {name: "ARMENIA-NAGORNI KARABAKH FIXED", five: 37, ten: 75, twenty: 150},
  {name: "ARUBA", five: 20, ten: 40, twenty: 80},
  {name: "ARUBA-CELLULAR", five: 22, ten: 45, twenty: 90},
  {name: "ASCENSION ISLAND", five: 7, ten: 14, twenty: 28},
  {name: "AUSTRALIA", five: 124, ten: 248, twenty: 497},
  {name: "AUSTRALIA-ADELAIDE", five: 124, ten: 248, twenty: 497},
  {name: "AUSTRALIA-CELLULAR", five: 32, ten: 64, twenty: 129},
  {name: "AUSTRALIA-MELBOURNE", five: 124, ten: 248, twenty: 497},
  {name: "AUSTRALIA-SPECIAL", five: 5, ten: 10, twenty: 20},
  {name: "AUSTRALIA-SYDNEY", five: 161, ten: 323, twenty: 647},
  {name: "AUSTRIA", five: 125, ten: 250, twenty: 501},
  {name: "AUSTRIA-CELLULAR", five: 26, ten: 53, twenty: 106},
  {name: "AUSTRIA-SPECIAL SERVICES", five: 18, ten: 37, twenty: 74},
  {name: "AUSTRIA-VIENNA", five: 144, ten: 289, twenty: 579},
  {name: "AZERBAIJAN", five: 20, ten: 41, twenty: 83},
  {name: "AZERBAIJAN-CELLULAR", five: 19, ten: 38, twenty: 76},
  {name: "BAHAMAS", five: 36, ten: 72, twenty: 144},
  {name: "BAHAMAS-CELLULAR", five: 24, ten: 49, twenty: 99},
  {name: "BAHRAIN", five: 39, ten: 78, twenty: 157},
  {name: "BAHRAIN-CELLULAR", five: 27, ten: 55, twenty: 111},
  {name: "BAHRAIN-CELLULAR SPECIAL", five: 27, ten: 55, twenty: 111},
  {name: "BANGLADESH", five: 166, ten: 333, twenty: 666},
  {name: "BANGLADESH-CELLULAR", five: 85, ten: 170, twenty: 341},
  {name: "BANGLADESH-DHAKA", five: 119, ten: 238, twenty: 476},
  {name: "BARBADOS", five: 22, ten: 45, twenty: 90},
  {name: "BARBADOS-CELLULAR", five: 17, ten: 35, twenty: 71},
  {name: "BARBADOS-CELLULAR C & W", five: 17, ten: 35, twenty: 71},
  {name: "BELARUS-CELLULAR", five: 19, ten: 39, twenty: 79},
  {name: "BELARUS-MINSK", five: 21, ten: 43, twenty: 86},
  {name: "BELARUS-SPECIAL", five: 19, ten: 39, twenty: 79},
  {name: "BELGIUM", five: 110, ten: 220, twenty: 440},
  {name: "BELGIUM-BRUSSELS", five: 110, ten: 220, twenty: 440},
  {name: "BELGIUM-CELLULAR", five: 12, ten: 25, twenty: 51},
  {name: "BELGIUM-CELLULAR OTHER", five: 12, ten: 25, twenty: 51},
  {name: "BELIZE", five: 17, ten: 35, twenty: 71},
  {name: "BELIZE-CELLULAR", five: 17, ten: 35, twenty: 70},
  {name: "BENIN", five: 18, ten: 36, twenty: 73},
  {name: "BENIN-CELLULAR", five: 21, ten: 43, twenty: 86},
  {name: "BERMUDA", five: 61, ten: 122, twenty: 244},
  {name: "BERMUDA-CELLULAR", five: 57, ten: 114, twenty: 229},
  {name: "BHUTAN", five: 17, ten: 34, twenty: 69},
  {name: "BHUTAN-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "BOLIVIA", five: 53, ten: 106, twenty: 212},
  {name: "BOLIVIA-CELLULAR", five: 33, ten: 66, twenty: 133},
  {name: "BOLIVIA-CELLULAR ENTEL", five: 33, ten: 66, twenty: 133},
  {name: "BOLIVIA-COCHABAMBA", five: 65, ten: 131, twenty: 263},
  {name: "BOLIVIA-LA PAZ", five: 65, ten: 131, twenty: 263},
  {name: "BOLIVIA-ORURO", five: 53, ten: 106, twenty: 212},
  {name: "BOLIVIA-POTOSI", five: 53, ten: 106, twenty: 212},
  {name: "BOLIVIA-RURAL", five: 53, ten: 106, twenty: 212},
  {name: "BOLIVIA-SANTA CRUZ", five: 43, ten: 86, twenty: 173},
  {name: "BOLIVIA-SUCRE", five: 53, ten: 106, twenty: 212},
  {name: "BOSNIA-HERZEGOVINA", five: 26, ten: 52, twenty: 105},
  {name: "BOSNIA-HERZEGOVINA CELLULAR", five: 14, ten: 28, twenty: 57},
  {name: "BOTSWANA", five: 24, ten: 48, twenty: 96},
  {name: "BOTSWANA-CELLULAR", five: 21, ten: 42, twenty: 85},
  {name: "BRAZIL", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-BELO HORIZONTE", five: 90, ten: 181, twenty: 363},
  {name: "BRAZIL-BRASILIA", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-CELLULAR", five: 15, ten: 31, twenty: 63},
  {name: "BRAZIL-CELLULAR CLARO", five: 15, ten: 31, twenty: 63},
  {name: "BRAZIL-CELLULAR OI", five: 15, ten: 31, twenty: 63},
  {name: "BRAZIL-CELLULAR TIM", five: 15, ten: 31, twenty: 63},
  {name: "BRAZIL-CELLULAR VIVO", five: 15, ten: 31, twenty: 63},
  {name: "BRAZIL-CURITIBA", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-FLORIANOPOLIS", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-FORTALEZA", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-GOIANIA", five: 72, ten: 145, twenty: 291},
  {name: "BRAZIL-GOV.VALADARES LD", five: 72, ten: 145, twenty: 291},
  {name: "BRAZIL-MANAUS", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-PORTO ALEGRE", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-RECIFE", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-RIO DE JANEIRO", five: 111, ten: 222, twenty: 444},
  {name: "BRAZIL-SALVADOR", five: 75, ten: 151, twenty: 303},
  {name: "BRAZIL-SAO PAULO", five: 113, ten: 227, twenty: 454},
  {name: "BRAZIL-VITORIA", five: 75, ten: 151, twenty: 303},
  {name: "BRITISH VIRGIN ISLAND", five: 26, ten: 52, twenty: 105},
  {name: "BRITISH VIRGIN ISLAND-CELLULAR", five: 26, ten: 52, twenty: 105},
  {name: "BRUNEI", five: 98, ten: 196, twenty: 392},
  {name: "BRUNEI-CELLULAR", five: 97, ten: 195, twenty: 391},
  {name: "BULGARIA", five: 108, ten: 216, twenty: 433},
  {name: "BULGARIA-CELLULAR", five: 11, ten: 22, twenty: 44},
  {name: "BULGARIA-CELLULAR SPECIAL", five: 11, ten: 22, twenty: 44},
  {name: "BULGARIA-SOFIA", five: 111, ten: 222, twenty: 445},
  {name: "BURKINA FASO", five: 19, ten: 39, twenty: 79},
  {name: "BURUNDI", five: 50, ten: 100, twenty: 200},
  {name: "BURUNDI-CELLULAR", five: 50, ten: 100, twenty: 200},
  {name: "CAMBODIA", five: 27, ten: 55, twenty: 110},
  {name: "CAMBODIA-CELLULAR", five: 27, ten: 55, twenty: 110},
  {name: "CAMBODIA-CELLULAR SMART", five: 5, ten: 10, twenty: 20},
  {name: "CAMBODIA-PHNOM PENH", five: 14, ten: 28, twenty: 56},
  {name: "CAMEROON", five: 45, ten: 90, twenty: 181},
  {name: "CAMEROON-CELLULAR", five: 16, ten: 32, twenty: 64},
  {name: "CAMEROON-CELLULAR MTN", five: 16, ten: 32, twenty: 64},
  {name: "CAMEROON-DOUALA", five: 25, ten: 51, twenty: 103},
  {name: "CAMEROON-SPECIAL", five: 5, ten: 10, twenty: 20},
  {name: "CANADA", five: 111, ten: 222, twenty: 444},
  {name: "CAPE VERDE", five: 16, ten: 33, twenty: 66},
  {name: "CAPE VERDE-CELLULAR", five: 16, ten: 33, twenty: 66},
  {name: "CAYMAN ISLANDS", five: 27, ten: 54, twenty: 108},
  {name: "CAYMAN ISLANDS-CELLULAR", five: 27, ten: 54, twenty: 108},
  {name: "CAYMAN ISLANDS-CELLULAR C&W", five: 5, ten: 10, twenty: 20},
  {name: "CAYMAN ISLANDS-CELLULAR DIGICEL", five: 23, ten: 47, twenty: 94},
  {name: "CENTRAL AFRICAN REPUBLIC", five: 11, ten: 23, twenty: 47},
  {name: "CENTRAL AFRICAN REPUBLIC-AUDIOTEXT", five: 2, ten: 5, twenty: 10},
  {name: "CENTRAL AFRICAN REPUBLIC-CELLULAR", five: 12, ten: 25, twenty: 50},
  {name: "CHAD", five: 23, ten: 47, twenty: 95},
  {name: "CHAD-CELLULAR", five: 18, ten: 36, twenty: 72},
  {name: "CHAD-CELLULAR CELTEL", five: 18, ten: 36, twenty: 72},
  {name: "CHAD-CELLULAR MILLICOM", five: 18, ten: 36, twenty: 72},
  {name: "CHILE", five: 122, ten: 245, twenty: 491},
  {name: "CHILE-AUDIOTEXT", five: 5, ten: 10, twenty: 20},
  {name: "CHILE-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "CHILE-CELLULAR CLARO", five: 20, ten: 41, twenty: 83},
  {name: "CHILE-EASTER ISLAND", five: 122, ten: 245, twenty: 491},
  {name: "CHILE-RURAL", five: 122, ten: 245, twenty: 491},
  {name: "CHILE-SANTIAGO", five: 112, ten: 225, twenty: 451},
  {name: "CHINA", five: 131, ten: 263, twenty: 526},
  {name: "CHINA-BEIJING", five: 122, ten: 244, twenty: 488},
  {name: "CHINA-CELLULAR", five: 111, ten: 222, twenty: 444},
  {name: "CHINA-FUZHOU", five: 167, ten: 334, twenty: 668},
  {name: "CHINA-GUANGZHOU", five: 158, ten: 317, twenty: 634},
  {name: "CHINA-SHANGHAI", five: 111, ten: 222, twenty: 444},
  {name: "CHRISTMAS ISLAND", five: 8, ten: 17, twenty: 34},
  {name: "COCOS ISLAND", five: 3, ten: 7, twenty: 14},
  {name: "COLOMBIA", five: 68, ten: 137, twenty: 275},
  {name: "COLOMBIA-ARMENIA", five: 64, ten: 129, twenty: 259},
  {name: "COLOMBIA-BARRANQUILLA", five: 111, ten: 222, twenty: 444},
  {name: "COLOMBIA-BOGOTA", five: 100, ten: 200, twenty: 400},
  {name: "COLOMBIA-BUCARAMANGA", five: 96, ten: 193, twenty: 386},
  {name: "COLOMBIA-CALI", five: 110, ten: 221, twenty: 442},
  {name: "COLOMBIA-CARTAGENA", five: 78, ten: 156, twenty: 312},
  {name: "COLOMBIA-CELLULAR", five: 36, ten: 72, twenty: 144},
  {name: "COLOMBIA-CELLULAR COMCEL", five: 41, ten: 82, twenty: 165},
  {name: "COLOMBIA-CELLULAR MOVISTAR", five: 40, ten: 81, twenty: 162},
  {name: "COLOMBIA-CELLULAR TIGO", five: 35, ten: 71, twenty: 142},
  {name: "COLOMBIA-LEX", five: 36, ten: 72, twenty: 144},
  {name: "COLOMBIA-MANIZALES", five: 58, ten: 117, twenty: 235},
  {name: "COLOMBIA-MEDELLIN", five: 92, ten: 185, twenty: 371},
  {name: "COLOMBIA-NEIVA", five: 58, ten: 117, twenty: 235},
  {name: "COLOMBIA-OTRAS CIUDADES", five: 86, ten: 173, twenty: 347},
  {name: "COLOMBIA-PEREIRA", five: 58, ten: 117, twenty: 235},
  {name: "COLOMBIA-TUNJA", five: 58, ten: 117, twenty: 235},
  {name: "COLOMBIA-VALLEDUPAR", five: 52, ten: 105, twenty: 210},
  {name: "COLOMBIA-VILLAVICENCIO", five: 52, ten: 105, twenty: 210},
  {name: "COMOROS", five: 14, ten: 29, twenty: 58},
  {name: "COMOROS-CELLULAR", five: 14, ten: 29, twenty: 58},
  {name: "CONGO", five: 25, ten: 50, twenty: 100},
  {name: "CONGO-CELLULAR", five: 25, ten: 50, twenty: 100},
  {name: "COOK ISLAND", five: 6, ten: 13, twenty: 26},
  {name: "COSTA RICA", five: 58, ten: 117, twenty: 235},
  {name: "COSTA RICA-CELLULAR", five: 52, ten: 105, twenty: 210},
  {name: "COSTA RICA-CELLULAR CLARO", five: 52, ten: 105, twenty: 210},
  {name: "COSTA RICA-CELLULAR MOVISTAR", five: 52, ten: 105, twenty: 210},
  {name: "CROATIA", five: 90, ten: 181, twenty: 363},
  {name: "CROATIA-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "CUBA", five: 5, ten: 11, twenty: 22},
  {name: "CUBA-CELLULAR", five: 5, ten: 11, twenty: 22},
  {name: "CUBA-GUANTANAMO BAY", five: 5, ten: 11, twenty: 22},
  {name: "CYPRUS", five: 111, ten: 222, twenty: 444},
  {name: "CYPRUS-CELLULAR", five: 71, ten: 142, twenty: 285},
  {name: "CZECH REPUBLIC", five: 99, ten: 198, twenty: 396},
  {name: "CZECH REPUBLIC-CELLULAR", five: 16, ten: 33, twenty: 66},
  {name: "DENMARK", five: 125, ten: 251, twenty: 502},
  {name: "DENMARK-CELLULAR", five: 20, ten: 40, twenty: 81},
  {name: "DENMARK-CELLULAR SPECIAL", five: 20, ten: 40, twenty: 81},
  {name: "DENMARK-COPENHAGEN", five: 125, ten: 251, twenty: 502},
  {name: "DIEGO GARCIA", five: 2, ten: 4, twenty: 8},
  {name: "DJIBOUTI", five: 11, ten: 23, twenty: 46},
  {name: "DOMINICA", five: 18, ten: 36, twenty: 72},
  {name: "DOMINICA-AUDIOTEXT", five: 22, ten: 45, twenty: 90},
  {name: "DOMINICA-CELLULAR", five: 18, ten: 37, twenty: 75},
  {name: "DOMINICAN REPUBLIC", five: 92, ten: 185, twenty: 371},
  {name: "DOMINICAN REPUBLIC-CELLULAR", five: 44, ten: 89, twenty: 178},
  {name: "DOMINICAN REPUBLIC-SANTO DOMINGO", five: 91, ten: 182, twenty: 365},
  {name: "ECUADOR", five: 25, ten: 50, twenty: 100},
  {name: "ECUADOR-CELLULAR ALEGRO", five: 20, ten: 40, twenty: 81},
  {name: "ECUADOR-CELLULAR MOVISTAR", five: 20, ten: 40, twenty: 81},
  {name: "ECUADOR-CELLULAR PORTA", five: 15, ten: 31, twenty: 62},
  {name: "ECUADOR-CNT", five: 25, ten: 50, twenty: 100},
  {name: "ECUADOR-ETAPA", five: 39, ten: 78, twenty: 157},
  {name: "ECUADOR-GUAYAQUIL", five: 24, ten: 49, twenty: 98},
  {name: "ECUADOR-QUITO", five: 36, ten: 72, twenty: 145},
  {name: "ECUADOR-SETEL", five: 38, ten: 76, twenty: 153},
  {name: "EGYPT", five: 25, ten: 51, twenty: 103},
  {name: "EGYPT-CAIRO", five: 25, ten: 51, twenty: 103},
  {name: "EGYPT-CELLULAR", five: 28, ten: 57, twenty: 115},
  {name: "EL SALVADOR", five: 22, ten: 44, twenty: 89},
  {name: "EL SALVADOR-CELLULAR", five: 18, ten: 37, twenty: 74},
  {name: "EL SALVADOR-CELLULAR CTE", five: 18, ten: 37, twenty: 74},
  {name: "EL SALVADOR-CELLULAR DIGICEL", five: 18, ten: 37, twenty: 74},
  {name: "EL SALVADOR-CELLULAR TELEFONICA", five: 18, ten: 37, twenty: 74},
  {name: "EL SALVADOR-CELLULAR TELEMOVIL", five: 18, ten: 37, twenty: 74},
  {name: "EL SALVADOR-CTE FIXED", five: 27, ten: 54, twenty: 108},
  {name: "EL SALVADOR-PERSONAL FIXED", five: 27, ten: 54, twenty: 108},
  {name: "EL SALVADOR-TELEFONICA FIXED", five: 20, ten: 40, twenty: 80},
  {name: "EQUATORIAL GUINEA", five: 20, ten: 41, twenty: 83},
  {name: "EQUATORIAL GUINEA-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "ERITREA", five: 14, ten: 28, twenty: 57},
  {name: "ESTONIA", five: 156, ten: 312, twenty: 625},
  {name: "ESTONIA-CELLULAR", five: 19, ten: 39, twenty: 79},
  {name: "ESTONIA-CELLULAR SPECIAL", five: 19, ten: 39, twenty: 79},
  {name: "ETHIOPIA", five: 16, ten: 32, twenty: 64},
  {name: "ETHIOPIA-ADDIS ABABA FIXED", five: 16, ten: 32, twenty: 64},
  {name: "ETHIOPIA-CELLULAR", five: 10, ten: 21, twenty: 43},
  {name: "ETHIOPIA-CELLULAR ADDIS ABABA", five: 10, ten: 21, twenty: 43},
  {name: "FAEROE ISLANDS", five: 23, ten: 47, twenty: 95},
  {name: "FAEROE ISLANDS-CELLULAR", five: 23, ten: 47, twenty: 95},
  {name: "FALKLAND ISLANDS", five: 6, ten: 13, twenty: 26},
  {name: "FIJI", five: 15, ten: 30, twenty: 60},
  {name: "FIJI-CELLULAR", five: 15, ten: 30, twenty: 60},
  {name: "FINLAND", five: 125, ten: 250, twenty: 500},
  {name: "FINLAND-CELLULAR", five: 26, ten: 52, twenty: 104},
  {name: "FINLAND-CELLULAR SPECIAL", five: 26, ten: 52, twenty: 104},
  {name: "FINLAND-FIXED OTHER", five: 125, ten: 250, twenty: 500},
  {name: "FRANCE", five: 111, ten: 222, twenty: 444},
  {name: "FRANCE-CELLULAR", five: 24, ten: 48, twenty: 96},
  {name: "FRANCE-NGN", five: 125, ten: 250, twenty: 500},
  {name: "FRANCE-PARIS", five: 111, ten: 222, twenty: 444},
  {name: "FRENCH ANTILLES", five: 75, ten: 151, twenty: 303},
  {name: "FRENCH ANTILLES-CELLULAR", five: 12, ten: 25, twenty: 50},
  {name: "FRENCH ANTILLES-CELLULAR ORANGE", five: 5, ten: 10, twenty: 20},
  {name: "FRENCH GUIANA", five: 43, ten: 86, twenty: 173},
  {name: "FRENCH GUIANA-CELLULAR", five: 13, ten: 26, twenty: 52},
  {name: "FRENCH POLYNESIA", five: 21, ten: 43, twenty: 86},
  {name: "GABON", five: 38, ten: 76, twenty: 152},
  {name: "GABON-CELLULAR", five: 19, ten: 39, twenty: 79},
  {name: "GABON-CELLULAR SPECIAL", five: 19, ten: 39, twenty: 79},
  {name: "GAMBIA", five: 15, ten: 30, twenty: 61},
  {name: "GAMBIA-CELLULAR", five: 15, ten: 30, twenty: 61},
  {name: "GEORGIA", five: 30, ten: 60, twenty: 121},
  {name: "GEORGIA-CELLULAR", five: 25, ten: 50, twenty: 100},
  {name: "GERMANY", five: 120, ten: 240, twenty: 481},
  {name: "GERMANY-BERLIN", five: 128, ten: 257, twenty: 514},
  {name: "GERMANY-CELLULAR", five: 25, ten: 51, twenty: 103},
  {name: "GERMANY-CELLULAR SPECIAL", five: 25, ten: 51, twenty: 103},
  {name: "GERMANY-FRANKFURT", five: 135, ten: 270, twenty: 540},
  {name: "GERMANY-MUNICH", five: 128, ten: 257, twenty: 514},
  {name: "GERMANY-NGN", five: 166, ten: 333, twenty: 666},
  {name: "GHANA", five: 44, ten: 89, twenty: 179},
  {name: "GHANA-ACCRA", five: 44, ten: 88, twenty: 176},
  {name: "GHANA-CELLULAR", five: 18, ten: 37, twenty: 74},
  {name: "GIBRALTAR", five: 121, ten: 243, twenty: 487},
  {name: "GIBRALTAR-CELLULAR", five: 16, ten: 32, twenty: 64},
  {name: "GREECE", five: 80, ten: 161, twenty: 322},
  {name: "GREECE-ATHENS", five: 110, ten: 220, twenty: 441},
  {name: "GREECE-CELLULAR", five: 21, ten: 43, twenty: 87},
  {name: "GREENLAND", five: 7, ten: 15, twenty: 31},
  {name: "GREENLAND-CELLULAR", five: 6, ten: 13, twenty: 26},
  {name: "GRENADA", five: 27, ten: 54, twenty: 108},
  {name: "GRENADA-CELLULAR", five: 20, ten: 41, twenty: 82},
  {name: "GUADALOUPE", five: 75, ten: 150, twenty: 300},
  {name: "GUADALOUPE-CELLULAR", five: 13, ten: 26, twenty: 52},
  {name: "GUATEMALA-ATEL", five: 28, ten: 56, twenty: 112},
  {name: "GUATEMALA-CLARO", five: 64, ten: 129, twenty: 259},
  {name: "GUATEMALA-MOBILE CLARO", five: 34, ten: 69, twenty: 139},
  {name: "GUATEMALA-MOBILE TELEFONICA", five: 13, ten: 26, twenty: 53},
  {name: "GUATEMALA-MOBILE TIGO", five: 27, ten: 54, twenty: 109},
  {name: "GUATEMALA-PROPER", five: 28, ten: 56, twenty: 112},
  {name: "GUINEA", five: 10, ten: 21, twenty: 43},
  {name: "GUINEA BISSAU", five: 7, ten: 15, twenty: 30},
  {name: "GUINEA BISSAU-CELLULAR", five: 10, ten: 21, twenty: 43},
  {name: "GUINEA-CELLULAR", five: 10, ten: 20, twenty: 40},
  {name: "GUYANA", five: 14, ten: 29, twenty: 58},
  {name: "GUYANA-CELLULAR", five: 12, ten: 24, twenty: 48},
  {name: "GUYANA-CELLULAR DIGICEL", five: 12, ten: 24, twenty: 48},
  {name: "HAITI", five: 18, ten: 36, twenty: 72},
  {name: "HAITI-CELLULAR", five: 15, ten: 30, twenty: 61},
  {name: "HAITI-MOBILE-COMCEL", five: 15, ten: 30, twenty: 61},
  {name: "HAITI-MOBILE-DIGICEL", five: 14, ten: 29, twenty: 58},
  {name: "HAITI-MOBILE-HAITEL", five: 15, ten: 30, twenty: 61},
  {name: "HAITI-PORT AU PRINCE", five: 25, ten: 50, twenty: 100},
  {name: "HONDURAS", five: 40, ten: 80, twenty: 160},
  {name: "HONDURAS-CELLULAR CELTEL", five: 29, ten: 58, twenty: 117},
  {name: "HONDURAS-CELLULAR DIGICEL", five: 34, ten: 68, twenty: 137},
  {name: "HONDURAS-CELLULAR HONDUTEL", five: 40, ten: 80, twenty: 160},
  {name: "HONDURAS-CELLULAR MEGATEL", five: 40, ten: 80, twenty: 160},
  {name: "HONDURAS-FIXED OFFNET", five: 40, ten: 80, twenty: 160},
  {name: "HONDURAS-TEGUCIGALPA", five: 40, ten: 80, twenty: 160},
  {name: "HONG KONG", five: 142, ten: 285, twenty: 571},
  {name: "HONG KONG-CELLULAR", five: 125, ten: 250, twenty: 500},
  {name: "HUNGARY", five: 113, ten: 227, twenty: 455},
  {name: "HUNGARY-BUDAPEST", five: 113, ten: 227, twenty: 455},
  {name: "HUNGARY-CELLULAR", five: 24, ten: 49, twenty: 98},
  {name: "ICELAND", five: 128, ten: 256, twenty: 512},
  {name: "ICELAND-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "ICELAND-CELLULAR SPECIAL", five: 20, ten: 41, twenty: 83},
  {name: "INDIA", five: 83, ten: 166, twenty: 333},
  {name: "INDIA-AHMEDABAD", five: 56, ten: 113, twenty: 226},
  {name: "INDIA-BANGALORE", five: 55, ten: 110, twenty: 220},
  {name: "INDIA-BARODA", five: 49, ten: 99, twenty: 198},
  {name: "INDIA-BHOPAL", five: 54, ten: 108, twenty: 217},
  {name: "INDIA-BOMBAY", five: 54, ten: 109, twenty: 218},
  {name: "INDIA-BSNL CEL", five: 53, ten: 107, twenty: 214},
  {name: "INDIA-CALCUTA", five: 55, ten: 111, twenty: 222},
  {name: "INDIA-CELLULAR", five: 76, ten: 153, twenty: 307},
  {name: "INDIA-GUJARAT", five: 51, ten: 103, twenty: 207},
  {name: "INDIA-HYDERABAD", five: 55, ten: 110, twenty: 220},
  {name: "INDIA-JAIPUR", five: 45, ten: 91, twenty: 183},
  {name: "INDIA-KANPUR", five: 45, ten: 90, twenty: 181},
  {name: "INDIA-KARNATAKA", five: 51, ten: 103, twenty: 207},
  {name: "INDIA-MADRAS", five: 55, ten: 110, twenty: 220},
  {name: "INDIA-NEW DELHI", five: 55, ten: 111, twenty: 223},
  {name: "INDIA-PUNJAB", five: 51, ten: 103, twenty: 207},
  {name: "INDIA-SURAT", five: 49, ten: 99, twenty: 199},
  {name: "INDONESIA", five: 44, ten: 89, twenty: 178},
  {name: "INDONESIA-CELLULAR", five: 33, ten: 66, twenty: 132},
  {name: "INDONESIA-CELLULAR SATELLITE", five: 33, ten: 66, twenty: 132},
  {name: "INDONESIA-CELLULAR-EXELCOM", five: 33, ten: 66, twenty: 132},
  {name: "INDONESIA-CELLULAR-INDOSAT", five: 33, ten: 66, twenty: 132},
  {name: "INDONESIA-CELLULAR-TELEKOM", five: 33, ten: 66, twenty: 132},
  {name: "INDONESIA-JAKARTA", five: 46, ten: 93, twenty: 186},
  {name: "INDONESIA-SURABAYA", five: 106, ten: 212, twenty: 425},
  {name: "IRAN", five: 39, ten: 79, twenty: 158},
  {name: "IRAN-CELLULAR", five: 28, ten: 56, twenty: 112},
  {name: "IRAN-TEHERAN", five: 39, ten: 78, twenty: 156},
  {name: "IRAQ", five: 31, ten: 62, twenty: 125},
  {name: "IRAQ-BAGHDAD", five: 35, ten: 70, twenty: 141},
  {name: "IRAQ-MOBILE", five: 28, ten: 57, twenty: 114},
  {name: "IRELAND", five: 142, ten: 284, twenty: 569},
  {name: "IRELAND SPECIAL SERVICES", five: 142, ten: 284, twenty: 569},
  {name: "IRELAND-CELLULAR", five: 17, ten: 35, twenty: 71},
  {name: "IRELAND-DUBLIN", five: 133, ten: 267, twenty: 534},
  {name: "IRIDIUM", five: 0, ten: 1, twenty: 3},
  {name: "ISRAEL", five: 119, ten: 238, twenty: 476},
  {name: "ISRAEL-CELLULAR", five: 35, ten: 71, twenty: 142},
  {name: "ISRAEL-FIXED NETWORK PALTEL", five: 18, ten: 36, twenty: 73},
  {name: "ISRAEL-MOBILE", five: 35, ten: 71, twenty: 142},
  {name: "ISRAEL-PALESTINE MOBILE", five: 15, ten: 31, twenty: 62},
  {name: "ISRAEL-TEL AVIV", five: 119, ten: 238, twenty: 476},
  {name: "ITALY", five: 116, ten: 233, twenty: 467},
  {name: "ITALY-CELLULAR", five: 20, ten: 40, twenty: 80},
  {name: "ITALY-CELLULAR SPECIAL", five: 20, ten: 40, twenty: 80},
  {name: "ITALY-MILAN", five: 112, ten: 224, twenty: 449},
  {name: "ITALY-NGN", five: 18, ten: 36, twenty: 72},
  {name: "ITALY-ROME", five: 142, ten: 285, twenty: 571},
  {name: "IVORY COAST", five: 16, ten: 33, twenty: 66},
  {name: "IVORY COAST-CELLULAR", five: 15, ten: 31, twenty: 62},
  {name: "IVORY COAST-CELLULAR-ORANGE", five: 14, ten: 29, twenty: 58},
  {name: "JAMAICA", five: 37, ten: 74, twenty: 149},
  {name: "JAMAICA-CELLULAR", five: 17, ten: 35, twenty: 71},
  {name: "JAMAICA-CELLULAR C&W", five: 17, ten: 35, twenty: 71},
  {name: "JAMAICA-CELLULAR DIGICEL", five: 16, ten: 33, twenty: 67},
  {name: "JAMAICA-PREMIUM", five: 20, ten: 41, twenty: 83},
  {name: "JAPAN", five: 105, ten: 210, twenty: 421},
  {name: "JAPAN-CELLULAR", five: 25, ten: 50, twenty: 100},
  {name: "JAPAN-OKINAWA", five: 113, ten: 227, twenty: 455},
  {name: "JAPAN-TOKYO", five: 105, ten: 210, twenty: 421},
  {name: "JORDAN", five: 24, ten: 49, twenty: 98},
  {name: "JORDAN-CELLULAR", five: 33, ten: 67, twenty: 134},
  {name: "JORDAN-CELLULAR SPECIAL", five: 33, ten: 67, twenty: 134},
  {name: "KAZAKHSTAN", five: 62, ten: 125, twenty: 250},
  {name: "KAZAKHSTAN ALMATY", five: 62, ten: 125, twenty: 250},
  {name: "KAZAKHSTAN ASTANA", five: 62, ten: 125, twenty: 250},
  {name: "KAZAKHSTAN-MOBILE", five: 27, ten: 54, twenty: 109},
  {name: "KAZAKHSTAN-PREMIUM", five: 5, ten: 10, twenty: 20},
  {name: "KAZAKHSTAN-SPECIAL", five: 5, ten: 10, twenty: 20},
  {name: "KENYA", five: 34, ten: 68, twenty: 136},
  {name: "KENYA-CELLULAR", five: 17, ten: 34, twenty: 69},
  {name: "KENYA-CELLULAR ORANGE", five: 17, ten: 34, twenty: 69},
  {name: "KENYA-CELLULAR SAFARICOM", five: 17, ten: 34, twenty: 69},
  {name: "KENYA-CELLULAR SPECIAL", five: 17, ten: 34, twenty: 69},
  {name: "KENYA-MOMBASSA", five: 35, ten: 71, twenty: 143},
  {name: "KENYA-NAIROBI", five: 43, ten: 86, twenty: 173},
  {name: "KIRIBATI", five: 6, ten: 12, twenty: 24},
  {name: "KOREA NORTH", five: 10, ten: 20, twenty: 40},
  {name: "KOREA SOUTH", five: 82, ten: 164, twenty: 328},
  {name: "KOREA SOUTH CELLULAR", five: 60, ten: 120, twenty: 240},
  {name: "KOREA SOUTH-SEOUL", five: 82, ten: 164, twenty: 328},
  {name: "KOSOVO-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "KOSOVO-PROPER", five: 5, ten: 10, twenty: 20},
  {name: "KUWAIT", five: 34, ten: 68, twenty: 137},
  {name: "KUWAIT-CELLULAR", five: 34, ten: 68, twenty: 137},
  {name: "KYRGYZSTAN", five: 41, ten: 83, twenty: 167},
  {name: "KYRGYZSTAN-BISHKEK", five: 41, ten: 83, twenty: 167},
  {name: "KYRGYZSTAN-CELLULAR", five: 47, ten: 95, twenty: 190},
  {name: "LAOS", five: 44, ten: 89, twenty: 179},
  {name: "LAOS-CELLULAR", five: 52, ten: 105, twenty: 210},
  {name: "LATVIA", five: 76, ten: 152, twenty: 304},
  {name: "LATVIA-CELLULAR", five: 23, ten: 47, twenty: 95},
  {name: "LATVIA-CELLULAR SPECIAL", five: 23, ten: 47, twenty: 95},
  {name: "LEBANON", five: 28, ten: 56, twenty: 112},
  {name: "LEBANON-BEIRUT", five: 62, ten: 125, twenty: 250},
  {name: "LEBANON-CELLULAR", five: 20, ten: 40, twenty: 81},
  {name: "LESOTHO", five: 16, ten: 33, twenty: 66},
  {name: "LESOTHO-CELLULAR", five: 19, ten: 38, twenty: 77},
  {name: "LESOTHO-CELLULAR SPECIAL", five: 19, ten: 38, twenty: 77},
  {name: "LIBERIA", five: 16, ten: 33, twenty: 66},
  {name: "LIBERIA-CELLULAR", five: 16, ten: 33, twenty: 66},
  {name: "LIBERIA-CELLULAR SPECIAL", five: 16, ten: 33, twenty: 66},
  {name: "LIBYA", five: 14, ten: 28, twenty: 57},
  {name: "LIBYA-CELLULAR", five: 16, ten: 33, twenty: 66},
  {name: "LIECHTENSTEIN", five: 77, ten: 155, twenty: 310},
  {name: "LIECHTENSTEIN-CELLULAR", five: 4, ten: 8, twenty: 17},
  {name: "LITHUANIA", five: 64, ten: 128, twenty: 256},
  {name: "LITHUANIA-CELLULAR", five: 25, ten: 51, twenty: 102},
  {name: "LITHUANIA-CELLULAR SPECIAL", five: 25, ten: 51, twenty: 102},
  {name: "LUXEMBOURG", five: 166, ten: 333, twenty: 666},
  {name: "LUXEMBOURG-CELLULAR", five: 18, ten: 37, twenty: 74},
  {name: "LUXEMBOURG-CELLULAR SPECIAL", five: 18, ten: 37, twenty: 74},
  {name: "MACAU", five: 93, ten: 186, twenty: 373},
  {name: "MACEDONIA", five: 14, ten: 29, twenty: 58},
  {name: "MACEDONIA-CELLULAR", five: 14, ten: 29, twenty: 58},
  {name: "MADAGASCAR", five: 24, ten: 48, twenty: 97},
  {name: "MADAGASCAR-CELLULAR", five: 22, ten: 44, twenty: 88},
  {name: "MADAGASCAR-CELLULAR SPECIAL", five: 6, ten: 13, twenty: 27},
  {name: "MALAWI", five: 28, ten: 57, twenty: 115},
  {name: "MALAWI-CELLULAR", five: 26, ten: 52, twenty: 104},
  {name: "MALAWI-CELLULAR OTHER", five: 5, ten: 10, twenty: 20},
  {name: "MALAYSIA", five: 100, ten: 200, twenty: 400},
  {name: "MALAYSIA-CELLULAR", five: 58, ten: 117, twenty: 235},
  {name: "MALDIVES", five: 18, ten: 37, twenty: 75},
  {name: "MALI REPUBLIC", five: 17, ten: 34, twenty: 68},
  {name: "MALI REPUBLIC-CELLULAR MALITEL", five: 17, ten: 34, twenty: 68},
  {name: "MALI REPUBLIC-CELLULAR ORANGE", five: 17, ten: 34, twenty: 68},
  {name: "MALI REPUBLIC-IKATEL FIXED", five: 17, ten: 34, twenty: 68},
  {name: "MALTA", five: 50, ten: 100, twenty: 201},
  {name: "MALTA-CELLULAR GOMOBILE", five: 5, ten: 10, twenty: 20},
  {name: "MARSHALL ISLANDS", five: 15, ten: 30, twenty: 60},
  {name: "MAURITANIA", five: 17, ten: 35, twenty: 70},
  {name: "MAURITANIA-CELLULAR", five: 17, ten: 35, twenty: 70},
  {name: "MAURITIUS-CELLULAR", five: 18, ten: 36, twenty: 73},
  {name: "MAYOTTE ISLAND-CELLULAR", five: 15, ten: 31, twenty: 62},
  {name: "MEXICO", five: 166, ten: 333, twenty: 666},
  {name: "MEXICO-CELLULAR", five: 87, ten: 175, twenty: 350},
  {name: "MEXICO-CELLULAR MOVISTAR", five: 89, ten: 179, twenty: 359},
  {name: "MEXICO-CELLULAR TELCEL", five: 89, ten: 179, twenty: 359},
  {name: "MEXICO-GUADALAJARA", five: 125, ten: 251, twenty: 502},
  {name: "MEXICO-MEXICO CITY", five: 142, ten: 285, twenty: 571},
  {name: "MEXICO-MONTERREY", five: 142, ten: 285, twenty: 571},
  {name: "MEXICO-PUEBLA", five: 142, ten: 285, twenty: 571},
  {name: "MEXICO-TIJUANA", five: 142, ten: 285, twenty: 571},
  {name: "MICRONESIA", five: 17, ten: 35, twenty: 71},
  {name: "MOLDOVA", five: 26, ten: 52, twenty: 104},
  {name: "MONACO", five: 131, ten: 262, twenty: 524},
  {name: "MONACO-CELLULAR", five: 24, ten: 48, twenty: 96},
  {name: "MONACO-KOSOVO CELLULAR", five: 13, ten: 26, twenty: 53},
  {name: "MONGOLIA", five: 25, ten: 50, twenty: 100},
  {name: "MONGOLIA-MOBILE AND SPECIAL SERVICES", five: 25, ten: 50, twenty: 100},
  {name: "MONTENEGRO", five: 45, ten: 91, twenty: 183},
  {name: "MONTENEGRO-CELLULAR", five: 21, ten: 42, twenty: 85},
  {name: "MONTSERRAT", five: 30, ten: 61, twenty: 122},
  {name: "MONTSERRAT-AUDIOTEXT", five: 27, ten: 55, twenty: 111},
  {name: "MOROCCO", five: 16, ten: 33, twenty: 67},
  {name: "MOROCCO-CASABLANCA", five: 21, ten: 43, twenty: 86},
  {name: "MOROCCO-CELLULAR", five: 10, ten: 21, twenty: 43},
  {name: "MOROCCO-CELLULAR MEDITEL", five: 11, ten: 23, twenty: 46},
  {name: "MOROCCO-CELLULAR WANA", five: 13, ten: 26, twenty: 52},
  {name: "MOROCCO-MEDITEL FIXED", five: 16, ten: 33, twenty: 67},
  {name: "MOROCCO-RABAT", five: 25, ten: 51, twenty: 102},
  {name: "MOZAMBIQUE", five: 22, ten: 45, twenty: 90},
  {name: "MOZAMBIQUE-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "MYANMAR", five: 12, ten: 25, twenty: 50},
  {name: "MYANMAR-CELLULAR", five: 12, ten: 25, twenty: 50},
  {name: "NAMIBIA", five: 47, ten: 95, twenty: 191},
  {name: "NAMIBIA-CELLULAR", five: 16, ten: 33, twenty: 66},
  {name: "NAURU", five: 4, ten: 8, twenty: 17},
  {name: "NEPAL", five: 18, ten: 37, twenty: 74},
  {name: "NEPAL-CELLULAR", five: 18, ten: 37, twenty: 74},
  {name: "NEPAL-KATMANDU", five: 18, ten: 36, twenty: 73},
  {name: "NETHERLANDS", five: 108, ten: 217, twenty: 435},
  {name: "NETHERLANDS ANTILLES", five: 31, ten: 62, twenty: 124},
  {name: "NETHERLANDS ANTILLES-CELLULAR", five: 30, ten: 61, twenty: 122},
  {name: "NETHERLANDS-AMSTERDAM", five: 108, ten: 217, twenty: 435},
  {name: "NETHERLANDS-CELLULAR", five: 22, ten: 45, twenty: 91},
  {name: "NETHERLANDS-CELLULAR SPECIAL", five: 22, ten: 45, twenty: 91},
  {name: "NEW CALEDONIA", five: 15, ten: 30, twenty: 61},
  {name: "NEW CALEDONIA-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "NEW ZEALAND", five: 111, ten: 222, twenty: 444},
  {name: "NEW ZEALAND-CELLULAR", five: 19, ten: 39, twenty: 79},
  {name: "NICARAGUA", five: 25, ten: 50, twenty: 101},
  {name: "NICARAGUA-CELLULAR CLARO", five: 17, ten: 35, twenty: 70},
  {name: "NICARAGUA-CELLULAR MOVISTAR", five: 18, ten: 36, twenty: 72},
  {name: "NIGER", five: 27, ten: 55, twenty: 111},
  {name: "NIGERIA", five: 24, ten: 48, twenty: 96},
  {name: "NIGERIA-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "NIGERIA-LAGOS", five: 31, ten: 63, twenty: 126},
  {name: "NIUE", five: 3, ten: 7, twenty: 15},
  {name: "NORFOLK ISLAND", five: 3, ten: 7, twenty: 14},
  {name: "NORWAY", five: 100, ten: 200, twenty: 400},
  {name: "NORWAY-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "NORWAY-OSLO", five: 100, ten: 200, twenty: 400},
  {name: "OMAN", five: 24, ten: 49, twenty: 99},
  {name: "OMAN-CELLULAR", five: 14, ten: 28, twenty: 56},
  {name: "PAKISTAN", five: 32, ten: 64, twenty: 128},
  {name: "PAKISTAN-CELLULAR", five: 31, ten: 63, twenty: 126},
  {name: "PAKISTAN-ISLAMABAD", five: 31, ten: 63, twenty: 126},
  {name: "PAKISTAN-KARACHI", five: 31, ten: 63, twenty: 127},
  {name: "PAKISTAN-LAHORE", five: 31, ten: 63, twenty: 127},
  {name: "PALAU", five: 16, ten: 32, twenty: 65},
  {name: "PANAMA", five: 125, ten: 250, twenty: 500},
  {name: "PANAMA-CELLULAR", five: 28, ten: 56, twenty: 113},
  {name: "PANAMA-CELLULAR C & W", five: 34, ten: 68, twenty: 136},
  {name: "PANAMA-CELLULAR CLARO", five: 34, ten: 68, twenty: 136},
  {name: "PANAMA-CITY", five: 114, ten: 228, twenty: 457},
  {name: "PAPUA NEW GUINEA", five: 5, ten: 11, twenty: 22},
  {name: "PARAGUAY", five: 55, ten: 110, twenty: 220},
  {name: "PARAGUAY-ASUNCION", five: 55, ten: 110, twenty: 220},
  {name: "PARAGUAY-CELLULAR", five: 35, ten: 70, twenty: 141},
  {name: "PERU", five: 76, ten: 153, twenty: 307},
  {name: "PERU-CELLULAR CLARO", five: 21, ten: 43, twenty: 86},
  {name: "PERU-CELLULAR CLARO LIMA", five: 21, ten: 43, twenty: 86},
  {name: "PERU-CELLULAR NEXTEL", five: 21, ten: 43, twenty: 86},
  {name: "PERU-CELLULAR TELEFONICA", five: 21, ten: 43, twenty: 86},
  {name: "PERU-CELLULAR TESAM", five: 21, ten: 43, twenty: 86},
  {name: "PERU-LIMA", five: 111, ten: 222, twenty: 444},
  {name: "PERU-ROC", five: 76, ten: 153, twenty: 307},
  {name: "PERU-RURAL", five: 76, ten: 153, twenty: 307},
  {name: "PHILIPPINES", five: 52, ten: 104, twenty: 208},
  {name: "PHILIPPINES-CELLULAR", five: 32, ten: 64, twenty: 128},
  {name: "PHILIPPINES-CELLULAR GLOBE", five: 32, ten: 64, twenty: 128},
  {name: "PHILIPPINES-CELLULAR SMART", five: 28, ten: 57, twenty: 114},
  {name: "PHILIPPINES-CELLULAR SUN", five: 23, ten: 47, twenty: 95},
  {name: "POLAND", five: 115, ten: 230, twenty: 460},
  {name: "POLAND-CELLULAR", five: 29, ten: 58, twenty: 116},
  {name: "POLAND-WARSAW", five: 119, ten: 238, twenty: 476},
  {name: "PORTUGAL", five: 100, ten: 200, twenty: 400},
  {name: "PORTUGAL-CELLULAR", five: 20, ten: 41, twenty: 83},
  {name: "PORTUGAL-CELLULAR VODAFONE", five: 5, ten: 10, twenty: 20},
  {name: "PORTUGAL-LISBON", five: 100, ten: 200, twenty: 400},
  {name: "QATAR", five: 16, ten: 33, twenty: 66},
  {name: "QATAR-CELLULAR", five: 16, ten: 32, twenty: 65},
  {name: "REUNION ISLAND", five: 57, ten: 114, twenty: 228},
  {name: "REUNION ISLAND-CELLULAR", five: 41, ten: 82, twenty: 165},
  {name: "ROMANIA", five: 42, ten: 85, twenty: 171},
  {name: "ROMANIA-BUCHAREST", five: 64, ten: 129, twenty: 259},
  {name: "ROMANIA-CELLULAR", five: 13, ten: 27, twenty: 54},
  {name: "ROMANIA-CELLULAR COSMOROM", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-CELLULAR DIGIMOBILE", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-CELLULAR ORANGE", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-CELLULAR TELEMOBIL", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-CELLULAR VODAFONE", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-PREMIUM", five: 5, ten: 10, twenty: 20},
  {name: "ROMANIA-SPECIAL", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA", five: 55, ten: 110, twenty: 220},
  {name: "RUSSIA-CELLULAR BEELINE", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA-CELLULAR MEGAFON", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA-CELLULAR MTS", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA-CELLULAR OTHER", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA-CELLULAR ROSTELECOM", five: 5, ten: 10, twenty: 20},
  {name: "RUSSIA-MOSCOW", five: 151, ten: 302, twenty: 604},
  {name: "RUSSIA-ST. PETERSBURG", five: 125, ten: 250, twenty: 500},
  {name: "RWANDA", five: 37, ten: 74, twenty: 149},
  {name: "RWANDA-CELLULAR", five: 12, ten: 25, twenty: 50},
  {name: "SAN MARINO", five: 83, ten: 166, twenty: 333},
  {name: "SAO TOME & PRINCIPE", five: 5, ten: 10, twenty: 21},
  {name: "SAUDI ARABIA", five: 24, ten: 49, twenty: 98},
  {name: "SAUDI ARABIA-CELLULAR", five: 18, ten: 36, twenty: 72},
  {name: "SAUDI ARABIA-DHAHRAN", five: 28, ten: 56, twenty: 112},
  {name: "SAUDI ARABIA-JEDDAH", five: 30, ten: 60, twenty: 120},
  {name: "SAUDI ARABIA-RIYADH", five: 44, ten: 89, twenty: 178},
  {name: "SENEGAL", five: 20, ten: 40, twenty: 80},
  {name: "SENEGAL-CELLULAR ORANGE", five: 12, ten: 25, twenty: 51},
  {name: "SENEGAL-CELLULAR TIGO", five: 18, ten: 36, twenty: 73},
  {name: "SENEGAL-DAKAR", five: 27, ten: 55, twenty: 111},
  {name: "SEYCHELLES", five: 31, ten: 63, twenty: 126},
  {name: "SIERRA LEONE", five: 9, ten: 18, twenty: 37},
  {name: "SIERRA LEONE-CELLULAR", five: 11, ten: 22, twenty: 44},
  {name: "SINGAPORE", five: 111, ten: 222, twenty: 444},
  {name: "SINGAPORE-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "SLOVAKIA", five: 78, ten: 156, twenty: 313},
  {name: "SLOVAKIA-BRATISLAVA", five: 5, ten: 10, twenty: 20},
  {name: "SLOVAKIA-CELLULAR", five: 17, ten: 35, twenty: 71},
  {name: "SLOVAKIA-CELLULAR O2", five: 5, ten: 10, twenty: 20},
  {name: "SLOVAKIA-CELLULAR ORANGE", five: 5, ten: 10, twenty: 20},
  {name: "SLOVAKIA-CELLULAR T-MOBILE", five: 5, ten: 10, twenty: 20},
  {name: "SLOVENIA", five: 102, ten: 204, twenty: 408},
  {name: "SLOVENIA-CELLULAR", five: 14, ten: 29, twenty: 59},
  {name: "SOLOMON ISLAND", five: 4, ten: 9, twenty: 18},
  {name: "SOUTH AFRICA", five: 25, ten: 51, twenty: 102},
  {name: "SOUTH AFRICA-CAPE TOWN", five: 25, ten: 51, twenty: 102},
  {name: "SOUTH AFRICA-CELLULAR", five: 22, ten: 44, twenty: 89},
  {name: "SOUTH AFRICA-JOHANNESBURG", five: 25, ten: 51, twenty: 102},
  {name: "SOUTH AFRICA-PRETORIA", five: 25, ten: 51, twenty: 102},
  {name: "SPAIN", five: 137, ten: 274, twenty: 549},
  {name: "SPAIN-BARCELONA", five: 121, ten: 242, twenty: 484},
  {name: "SPAIN-CELLULAR", five: 24, ten: 48, twenty: 97},
  {name: "SPAIN-MADRID", five: 142, ten: 285, twenty: 571},
  {name: "SPAIN-NGN", five: 125, ten: 250, twenty: 500},
  {name: "SRI LANKA", five: 34, ten: 69, twenty: 139},
  {name: "SRI LANKA-CELLULAR", five: 34, ten: 69, twenty: 139},
  {name: "SRI LANKA-LANKA BELL", five: 34, ten: 69, twenty: 139},
  {name: "SRI LANKA-SLT", five: 34, ten: 69, twenty: 139},
  {name: "ST. HELENA", five: 4, ten: 8, twenty: 17},
  {name: "ST. KITTS & NEVIS", five: 24, ten: 49, twenty: 98},
  {name: "ST. KITTS & NEVIS-CELLULAR", five: 17, ten: 34, twenty: 69},
  {name: "ST. KITTS & NEVIS-CELLULAR CW", five: 5, ten: 10, twenty: 20},
  {name: "ST. KITTS & NEVIS-CELLULAR OTHER", five: 5, ten: 10, twenty: 20},
  {name: "ST. LUCIA", five: 18, ten: 37, twenty: 75},
  {name: "ST. LUCIA-CELLULAR", five: 18, ten: 36, twenty: 73},
  {name: "ST. PIERRE & MIQUELLON", five: 22, ten: 44, twenty: 88},
  {name: "ST. PIERRE & MIQUELLON-CELLULAR", five: 10, ten: 21, twenty: 42},
  {name: "ST. VINCENT & GRENADA", five: 23, ten: 46, twenty: 92},
  {name: "ST. VINCENT & GRENADA-CELLULAR", five: 17, ten: 34, twenty: 69},
  {name: "ST. VINCENT & GRENADA-CELLULAR OTHER", five: 5, ten: 10, twenty: 20},
  {name: "SUDAN", five: 21, ten: 42, twenty: 85},
  {name: "SUDAN-CELLULAR", five: 22, ten: 44, twenty: 89},
  {name: "SURINAME", five: 20, ten: 40, twenty: 81},
  {name: "SURINAME-CELLULAR", five: 14, ten: 29, twenty: 59},
  {name: "SURINAME-CELLULAR DIGICEL", five: 5, ten: 10, twenty: 20},
  {name: "SWAZILAND", five: 32, ten: 64, twenty: 128},
  {name: "SWAZILAND-CELLULAR", five: 28, ten: 56, twenty: 112},
  {name: "SWEDEN", five: 90, ten: 181, twenty: 363},
  {name: "SWEDEN-CELLULAR", five: 26, ten: 53, twenty: 106},
  {name: "SWEDEN-STOCKHOLM", five: 90, ten: 181, twenty: 363},
  {name: "SWITZERLAND", five: 111, ten: 223, twenty: 447},
  {name: "SWITZERLAND-CELLULAR", five: 18, ten: 37, twenty: 75},
  {name: "SWITZERLAND-GENEVA", five: 111, ten: 223, twenty: 447},
  {name: "SWITZERLAND-ZURICH", five: 111, ten: 223, twenty: 447},
  {name: "SYRIA", five: 20, ten: 40, twenty: 80},
  {name: "SYRIA-CELLULAR", five: 20, ten: 40, twenty: 80},
  {name: "SYRIA-CELLULAR MTN", five: 20, ten: 40, twenty: 80},
  {name: "SYRIA-CELLULAR SYRIATEL", five: 20, ten: 40, twenty: 80},
  {name: "TAIWAN", five: 122, ten: 245, twenty: 490},
  {name: "TAIWAN-CELLULAR", five: 41, ten: 82, twenty: 165},
  {name: "TAIWAN-PNS", five: 42, ten: 84, twenty: 169},
  {name: "TAJIKISTAN", five: 29, ten: 58, twenty: 117},
  {name: "TAJIKISTAN-CELLULAR", five: 29, ten: 58, twenty: 117},
  {name: "TANZANIA", five: 22, ten: 44, twenty: 89},
  {name: "TANZANIA-CELLULAR", five: 20, ten: 40, twenty: 80},
  {name: "TANZANIA-DARESSALM", five: 33, ten: 66, twenty: 132},
  {name: "THAILAND", five: 128, ten: 257, twenty: 514},
  {name: "THAILAND-BANGKOK", five: 128, ten: 257, twenty: 514},
  {name: "THAILAND-CELLULAR", five: 287, ten: 574, twenty: 1149},
  {name: "THURAYA", five: 3, ten: 6, twenty: 13},
  {name: "TOGO REPUBLIC", five: 12, ten: 24, twenty: 49},
  {name: "TOKELAU", five: 4, ten: 9, twenty: 19},
  {name: "TONGA ISLAND", five: 15, ten: 31, twenty: 63},
  {name: "TRINIDAD & TOBAGO", five: 24, ten: 48, twenty: 97},
  {name: "TRINIDAD & TOBAGO-CELLULAR", five: 20, ten: 40, twenty: 80},
  {name: "TRISTAN DA CUNHA", five: 1, ten: 3, twenty: 7},
  {name: "TUNISIA", five: 10, ten: 20, twenty: 40},
  {name: "TUNISIA-CELLULAR", five: 10, ten: 20, twenty: 40},
  {name: "TURKEY", five: 80, ten: 160, twenty: 320},
  {name: "TURKEY-ADANA", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-ANKARA", five: 111, ten: 222, twenty: 444},
  {name: "TURKEY-ANTALYA", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-BURSA", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-ERZURUM", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-ESKISEHIR", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-ISTANBUL", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-IZMIR", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-KAYSERI", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-KONYA", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-MOBILE AVEA", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-MOBILE GLOBAL STAR(GMPCS)", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-MOBILE OTHER CARRIERS", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-MOBILE TURKCELL", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-MOBILE TURKCELL-CYPRUS", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-MOBILE VODAFONE", five: 25, ten: 50, twenty: 101},
  {name: "TURKEY-NORTH CYPRUS", five: 100, ten: 200, twenty: 401},
  {name: "TURKEY-TRABZON", five: 100, ten: 200, twenty: 401},
  {name: "TURKMENISTAN", five: 33, ten: 67, twenty: 135},
  {name: "TURKMENISTAN-CELLULAR", five: 5, ten: 10, twenty: 20},
  {name: "TURKS & CAICOS", five: 16, ten: 33, twenty: 67},
  {name: "TURKS & CAICOS-CELLULAR", five: 17, ten: 34, twenty: 68},
  {name: "TUVALU", five: 9, ten: 19, twenty: 39},
  {name: "UGANDA", five: 43, ten: 87, twenty: 175},
  {name: "UGANDA-CELLULAR", five: 33, ten: 66, twenty: 132},
  {name: "UKRAINE", five: 34, ten: 68, twenty: 137},
  {name: "UKRAINE-CELLULAR", five: 28, ten: 57, twenty: 114},
  {name: "UKRAINE-LVIV", five: 34, ten: 68, twenty: 137},
  {name: "UKRAINE-ODESSA", five: 34, ten: 68, twenty: 137},
  {name: "UNITED ARAB EMIRATES", five: 21, ten: 43, twenty: 86},
  {name: "UNITED ARAB EMIRATES-CELLULAR", five: 21, ten: 42, twenty: 84},
  {name: "UNITED ARAB EMIRATES-DUBAI", five: 21, ten: 43, twenty: 86},
  {name: "UNITED KINGDOM", five: 100, ten: 200, twenty: 400},
  {name: "UNITED KINGDOM-CELLULAR", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR H3G", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR O2", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR ORANGE", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR OTHER", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR T-MOBILE", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-CELLULAR VODAFONE", five: 24, ten: 48, twenty: 97},
  {name: "UNITED KINGDOM-LONDON", five: 148, ten: 296, twenty: 593},
  {name: "UNITED KINGDOM-SPECIAL", five: 12, ten: 25, twenty: 50},
  {name: "URUGUAY", five: 52, ten: 105, twenty: 210},
  {name: "URUGUAY-CELLULAR", five: 16, ten: 32, twenty: 64},
  {name: "URUGUAY-CELLULAR ANCEL", five: 5, ten: 10, twenty: 20},
  {name: "URUGUAY-MONTEVIDEO", five: 52, ten: 105, twenty: 210},
  {name: "USA-ALASKA", five: 30, ten: 60, twenty: 121},
  {name: "USA-GUAM", five: 30, ten: 60, twenty: 121},
  {name: "USA-HAWAII", five: 30, ten: 60, twenty: 121},
  {name: "USA-NON RBOC", five: 30, ten: 60, twenty: 121},
  {name: "USA-PUERTO RICO", five: 30, ten: 60, twenty: 121},
  {name: "USA-RBOC", five: 30, ten: 60, twenty: 121},
  {name: "USA-SAIPAN", five: 30, ten: 60, twenty: 121},
  {name: "USA-TOLL FREE", five: 30, ten: 60, twenty: 121},
  {name: "USA-VIRGIN ISLANDS", five: 30, ten: 60, twenty: 121},
  {name: "UZBEKISTAN", five: 48, ten: 97, twenty: 194},
  {name: "UZBEKISTAN-CELLULAR", five: 56, ten: 112, twenty: 224},
  {name: "VANUATU", five: 6, ten: 13, twenty: 26},
  {name: "VANUATU-AUDIOTEXT", five: 4, ten: 8, twenty: 16},
  {name: "VATICAN CITY", five: 19, ten: 38, twenty: 77},
  {name: "VENEZUELA", five: 30, ten: 61, twenty: 122},
  {name: "VENEZUELA-CARACAS", five: 90, ten: 180, twenty: 361},
  {name: "VENEZUELA-CELLULAR DIGITEL", five: 24, ten: 49, twenty: 99},
  {name: "VENEZUELA-CELLULAR MOVILNET", five: 24, ten: 49, twenty: 99},
  {name: "VENEZUELA-CELLULAR MOVISTAR", five: 24, ten: 49, twenty: 99},
  {name: "VENEZUELA-MARACAIBO", five: 95, ten: 190, twenty: 380},
  {name: "VIETNAM", five: 27, ten: 54, twenty: 109},
  {name: "VIETNAM-CELLULAR", five: 53, ten: 106, twenty: 212},
  {name: "VIETNAM-HO CHI MINH", five: 39, ten: 78, twenty: 157},
  {name: "WAKE ISLAND", five: 1, ten: 2, twenty: 5},
  {name: "WALLIS AND FUTUNA", five: 2, ten: 5, twenty: 11},
  {name: "WESTERN SAMOA", five: 10, ten: 21, twenty: 42},
  {name: "YEMEN", five: 25, ten: 51, twenty: 103},
  {name: "YEMEN-CELLULAR", five: 14, ten: 28, twenty: 56},
  {name: "ZAIRE", five: 15, ten: 30, twenty: 61},
  {name: "ZAIRE-CELLULAR", five: 13, ten: 27, twenty: 55},
  {name: "ZAIRE-CELLULAR SPECIAL", five: 13, ten: 27, twenty: 55},
  {name: "ZAMBIA", five: 76, ten: 153, twenty: 307},
  {name: "ZAMBIA-CELLULAR", five: 26, ten: 52, twenty: 104},
  {name: "ZIMBABWE", five: 50, ten: 101, twenty: 203},
  {name: "ZIMBABWE-CELLULAR", five: 14, ten: 28, twenty: 57},
  {name: "ZIMBABWE-CELLULAR SPECIAL", five: 13, ten: 26, twenty: 52}
]